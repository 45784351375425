import { useState } from 'react';

import styled from 'styled-components';
// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Divider from '@mui/material/Divider';
// Local
import ConditionalWrapper from './ConditionalWrapper';
import { withTooltip } from './helpers';

/**
 * Different sections in a Dialog. Intended to be used a child component of Dialog.
 * User can have have DialogContentSection as a child of DialogContentSection (ie.
 * a section has a subsection).
 * @param {{
 *     className: string,
 *     children: JSX.Element,
 *     title: string,
 *     collapsible: boolean,
 *     defaultExpand: boolean,
 *     dividers: boolean,
 *     style: object,
 *     sx: object,
 *     titleVariant: string,
 *     titleUpperCase: boolean,
 *     TitleProps: object,
 *     collapsedMarginBottom: number,
 *     disablePadding?: boolean,
 *     disablePaddingTop?: boolean,
 *     disablePaddingRight?: boolean,
 *     disablePaddingBottom?: boolean,
 *     disablePaddingLeft?: boolean,
 * }} props
 */
const DialogContentSection = styled(({
    className,
    children,
    title,
    collapsible=false,
    defaultExpand=false,
    dividers=false,
    style={},
    sx={},
    titleVariant='h6',
    titleUpperCase=true,
    TitleProps={},
    collapsedMarginBottom,
}) => {
    const [expand, setExpand] = useState(defaultExpand);
    const titleProps = { ...TitleProps };

    function handleClick() {
        setExpand((exp) => !exp);
    }

    function wrapper(componentChildren) {
        const otherProps = {};
        if (collapsedMarginBottom) {
            otherProps.sx = {
                marginBottom: expand ? 0 : collapsedMarginBottom,
                // MUI adds height transition by default, but we also want to transition the margin.
                // Unsetting the transition property allows it to apply the transition to both.
                transitionProperty: 'unset !important',
            };
        }
        return (
            <Collapse in={expand} {...otherProps}>
                {componentChildren}
            </Collapse>
        );
    }

    function handleKeyUp(e) {
        const { nativeEvent: { code } } = e;
        if (code === 'Space' || code === 'Enter') {
            setExpand((exp) => !exp);
        }
    }

    if (collapsible) {
        titleProps.onClick = handleClick;
        titleProps.role = 'button';
        titleProps['aria-expanded'] = expand;
        titleProps['wrap'] = 'nowrap';
        titleProps['tabIndex'] = 0;
        titleProps['onKeyUp'] = handleKeyUp;
    }

    return (
        <Box className={className} style={{ ...style }} sx={{ ...sx }}>
            {dividers && <Divider />}
            {
              title && (
              <Grid container justifyContent="space-between" alignItems="center" className="tabpanelsection__title" {...titleProps}>
                  <Typography variant={titleVariant} noWrap>
                      {titleUpperCase ? title.toUpperCase() : title}
                  </Typography>
                  {collapsible && (expand ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />)}
              </Grid>
              )
            }
            <ConditionalWrapper condition={collapsible} wrapper={wrapper}>
                <DialogContent>
                    {children}
                </DialogContent>
            </ConditionalWrapper>
            {dividers && <Divider />}
        </Box>
    );
})`
  padding: 0.5ex 0;

  & .tabpanelsection__title {
    cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'auto')};

    & .MuiTypography-root {
      font-weight: 700;
    }
  }

  & .MuiDialogContent-root {
    ${({ disablePadding, disablePaddingTop }) => ((disablePadding || disablePaddingTop) ? 'padding-top: 0;' : '')}
    ${({ disablePadding, disablePaddingRight }) => ((disablePadding || disablePaddingRight) ? 'padding-right: 0;' : '')}
    ${({ disablePadding, disablePaddingBottom }) => ((disablePadding || disablePaddingBottom) ? 'padding-bottom: 0;' : '')}
    ${({ disablePadding, disablePaddingLeft }) => ((disablePadding || disablePaddingLeft) ? 'padding-left: 0;' : '')}
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiDialogContent-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const DialogContentWithTooltip = withTooltip(DialogContentSection, {
    IconWrapperProps: { style: { paddingTop: '7px', width: 'unset' } },
    WrapperProps: { style: { justifyContent: 'space-between' } },
});

export default DialogContentSection;
