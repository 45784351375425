import { useExportDataFetch, defaultConfig } from '../exportUtils';

export default function ExportSampleTab({ selected }) {
    // Use the hook useExportDataFetch if you need to fetch any data when the tab is selected.
    // The data will be fetched according to the format(s) specified in the config.
    const { errors, formattedData, context } = useExportDataFetch(selected, defaultConfig);
    const data = formattedData['sdf'];
    console.log(`Rendering Sample Export Tab. Selected: ${selected}`);

    return (
        <div>
            <p>This is a Sample Export Tab.</p>
            <p>
                Data format request: $
                {defaultConfig.formats}
            </p>
            <p>
                {errors.length ? errors: data}
            </p>
        </div>
    );
}
