import { configureStore } from '@reduxjs/toolkit';
import listenerMiddleware from './middlewares/listenerMiddleware';
import prefsReducer from './prefs';
import { fireSubscriberCallbacks, register } from './subscribers/stateChangeSubscriber';
import projectStateReducer from './projectState';

const reducer = {
    prefs: prefsReducer,
    projectState: projectStateReducer,
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // removes error message for non-serializable data in redux
            ignoredActionPaths: ['payload.mapCase', 'payload.compounds'],
            ignoredPaths: ['projectState.targetList', 'projectState.scoringTable.rows'],
        },
    }).concat(listenerMiddleware),
});

// Add store subscribers
// The previous implementation of firing subscriber callbacks relied on a hack with createSelector.
// As the memoization behavior in that package changed, we need to find another way. Hopefully there
// is a better way than tracking the previous state here, but this lets us finish the upgrade.
// This PR concerns the previous hack: https://github.com/Conifer-Point/bmaps/pull/242/files
let previousState = { prefs: {}, projectState: {} };
store.subscribe(() => {
    const currentState = store.getState();
    fireSubscriberCallbacks(previousState, currentState);
    previousState = currentState;
});

register('prefs.ColorScheme', (state) => state.prefs.ColorScheme);
register('prefs.BindingSiteDistance', (state) => state.prefs.BindingSiteDistance);

export default store;
