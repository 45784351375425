import _ from 'lodash';

const registry = {};

export function register(key, selectorFn) {
    console.log(`Registering state subscriber ${key}`);
    if (registry[key]) {
        throw new Error(`State subscriber '${key}' already exists!`);
    }

    registry[key] = {
        selector: selectorFn,
        callbacks: [],
    };
}

export function subscribe(key, cb) {
    if (!registry[key]) {
        console.error(`A state subscriber for ${key} does not exist. Please add a "register" call in store.js!`);
        return;
    }

    registry[key].callbacks.push(cb);
}

export function fireSubscriberCallbacks(previousState, currentState) {
    Object.values(registry).forEach(({ selector, callbacks }) => {
        const prevState = selector(previousState);
        const newState = selector(currentState);
        if (!_.isEqual(prevState, newState)) {
            callbacks.forEach((cb) => cb(newState));
        }
    });
}
