import { useEffect } from 'react';
import { useFormikContext, Field, FastField } from 'formik';
import _ from 'lodash';
// Material UI
import { GridLegacy as Grid } from '@mui/material';
// Local
import InputError from '../InputError';

export const ErrorFieldRenderType = Object.freeze({
    ALWAYS: 'always',
    NEVER: 'never',
    WITH_ERROR: 'with_error',
});
/**
 * Links the component to Formik.
 *
 * @param {JSX.Element} Component - Field component to link to Formik.
 * @param {Boolean} hasErrorState - Whether or not the Component has an error prop.
 * @param {Boolean} defaultOptimize - Whether or not to use FastField by default. Note that
 *                                FastField has some conditions for usage. For more details,
 *                                see the following:
 *                                  https://formik.org/docs/api/fastfield
 * @param {String} errorFieldType - When to render the error field: always | never | with_error
 *
 * Note: Validation first occurs on a form level upon submit, as well as blur or change depending
 * on the main Formik control's configuration. Then, if validation found an error, fields will
 * be validated individually until they are correct.

 * Note: the returned component also accepts a `optimize` param to determine
 * whether to use FastField or Field for the component instance.
 * Pass defaultOptimize to withFormik to specify the default for a new component definition:
 *     `const MySlowField = withFormik(TextField, { defaultOptimize: false });`
 * Pass optimize into a component instance to specify on a one time basis.
 *     `const myFastInstance = <MySlowField optimize />`
 */
export default function withFormik(
    Component,
    {
        hasErrorState = false,
        defaultOptimize = true,
        errorFieldRenderType = ErrorFieldRenderType.ALWAYS,
    } = {}
) {
    return function FormikWrapper({
        name, validate, shouldUpdate: componentShouldUpdate, ContainerComponent = Grid,
        onChange = () => {}, onBlur = () => {},
        ContainerComponentProps = {}, optimize = defaultOptimize, ...rest
    }) {
        const {
            values, getFieldMeta, getFieldHelpers, getFieldProps,
        } = useFormikContext();
        const { value, touched, error } = getFieldMeta(name);
        const { setError } = getFieldHelpers(name);
        const { onChange: fieldOnChange, onBlur: fieldOnBlur } = getFieldProps(name);
        const FieldComponent = optimize ? FastField : Field;

        const changeHandler = (e) => {
            fieldOnChange(e);
            onChange(e);
        };
        const blurHandler = (e) => {
            fieldOnBlur(e);
            onBlur(e);
        };

        const componentProps = {
            name,
            onChange: changeHandler,
            onBlur: blurHandler,
            ...(FieldComponent === FastField && componentShouldUpdate !== undefined && {
                shouldUpdate: (nextProps, prevProps) => {
                    if (
                        prevProps.name !== nextProps.name
                            || prevProps.formik.values[nextProps.name]
                                !== nextProps.formik.values[nextProps.name]
                            || prevProps.formik.errors[nextProps.name]
                                !== nextProps.formik.errors[nextProps.name]
                            || prevProps.formik.touched[nextProps.name]
                                !== nextProps.formik.touched[nextProps.name]
                            || Object.keys(prevProps).length !== Object.keys(nextProps).length
                            || prevProps.formik.isSubmitting !== nextProps.formik.isSubmitting
                    ) {
                        return true;
                    }

                    return componentShouldUpdate(nextProps, prevProps);
                },
            }),
            ...(hasErrorState && { error: touched && !!error }),
            ...rest,
        };
        const containerComponentProps = {
            ...(ContainerComponent === Grid && {
                container: true,
            }),
            ...ContainerComponentProps,
        };

        // When field has an error, then it will validate on change. Once user enters valid value,
        // validate on change will be disabled until an error is encountered again (ie. fails
        // validation, such as on form submission).
        useEffect(() => {
            if (touched && error && validate) {
                const otherValues = _.omit(values, [name]);
                const err = validate(value, otherValues);
                if (err !== error) {
                    setError(err);
                }
            }
        }, [validate, touched, error, value]);

        const showErrorField = (
            errorFieldRenderType === ErrorFieldRenderType.ALWAYS
            || (errorFieldRenderType === ErrorFieldRenderType.WITH_ERROR && touched && error)
        );
        return (
            <ContainerComponent {...containerComponentProps}>
                <FieldComponent as={Component} {...componentProps} {...rest} />
                {!!showErrorField && <InputError error={touched && error} />}
            </ContainerComponent>
        );
    };
}
