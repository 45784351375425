/**
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {import('BMapsModel').MapCase} MapCase
 */
import React from 'react';
import { TabNav } from '@Conifer-Point/px-components';
import { App } from 'BMapsSrc/BMapsApp';
import { UserActions } from 'BMapsCmds';
import { IntegrationsInspectorTab } from 'BMapsSrc/integrations/IntegrationsInspectorTab';
import InspectorMessage from './InspectorMessage';
import { ActiveCompound2dViewer } from '../../ux2_2dviewer';
import { EnergyDisplay, RefreshButton } from './EnergyDisplay';
import { DisplayLID } from './DisplayLID';
import { encodeHtmlEntities } from '../../utils';
import { MolProps } from '../../model/compound';
import { CollapsibleTable } from '../CollapsibleTable';
import { updateTooltipster } from '../ui_utils';
import { GiFEInspector } from './GiFEInspector';
import { Loader } from '../../Loader';

/**
 * Bottom-Left Inspector Component - 2D Structure, Energies, Mol. Propreties, Interaction Diagram
 *
 * @param {Object} props
 * @param {string} props.tab
 * @param {Compound} props.activeCompound
 * @param {{ mainProtein: MapCase, allProteins: MapCase[]}} props.proteinInfo
 * @param {React.CSSProperties} props.backgroundStyle
 * @param {Compound[]} props.otherVisibleCompounds
 */
export default function Inspector({
    tab, activeCompound, proteinInfo, backgroundStyle, otherVisibleCompounds,
}) {
    const filteredVisibleCmpds = otherVisibleCompounds.filter((c) => c !== activeCompound);

    const showWithActiveCompound = (child) => {
        if (activeCompound) return child;
        const noCompounds = !App.Ready || App.Workspace.getLoadedCompounds().length === 0;
        return (
            <InspectorMessage>
                {
                    noCompounds ? 'No compounds available' : (
                        <div>
                            <p>No focus compound to display.</p>
                            <p>
                                Click
                                {' '}
                                <i className="fa fa-arrow-right" />
                                {' '}
                                for one of the compounds in the Selector above.
                            </p>
                        </div>
                    )
                }
            </InspectorMessage>
        );
    };
    return (
        <TabNav
            id="inspector"
            className="inspector"
            style={backgroundStyle}
            selected={tab}
            onSelect={(index, tabId) => UserActions.ShowInspectorTab(tabId)}
        >
            <TabNav.Page tabId="structure" title="Structure" className={activeCompound ? '' : 'padded_inspector_page'}>
                { showWithActiveCompound(
                    <ActiveCompound2dViewer compound={activeCompound} />,
                )}
            </TabNav.Page>
            <TabNav.Page tabId="energies" title="Energies" className="padded_inspector_page">
                { showWithActiveCompound(
                    <EnergyDisplay
                        proteinInfo={proteinInfo}
                        activeCompound={activeCompound}
                        otherCompounds={filteredVisibleCmpds}
                    />,
                )}
            </TabNav.Page>
            <TabNav.Page tabId="properties" title="Properties" className="padded_inspector_page">
                { showWithActiveCompound(
                    <MolPropsDisplay compounds={[activeCompound].concat(filteredVisibleCmpds)} />
                )}
            </TabNav.Page>
            <TabNav.Page tabId="interactionDiagram" title="LID" className={activeCompound ? '' : 'padded_inspector_page'}>
                { showWithActiveCompound(
                    <DisplayLID compound={activeCompound} />,
                )}
            </TabNav.Page>
            {!!Loader.AllowLabFeatures && (
                <TabNav.Page tabId="GiFE" title="GiFE" className="padded_inspector_page">
                    <GiFEInspector />
                </TabNav.Page>
            )}
            {!!Loader.AllowLabFeatures && (
                <TabNav.Page tabId="Integrations" title="Services" className="padded_inspector_page">
                    <IntegrationsInspectorTab activeCompound={activeCompound} />
                </TabNav.Page>
            )}
        </TabNav>
    );
}

const molPropsTableTitle = 'Mol. Properties';

/**
 * Component to display molecule properties in a collapsible table.
 */
class MolPropsDisplay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            working: false,
        };
    }

    refreshMolProps() {
        this.setState((prevState) => {
            if (!prevState.working) {
                this.queryMolProps();
                return { working: true };
            }
            return null;
        });
    }

    async queryMolProps() {
        const { compounds } = this.props;
        for (const c of compounds) {
            await c.updateMolProps();
        }
        this.setState({ working: false });
    }

    render() {
        const props = this.props;
        const compounds = props.compounds;

        if (!compounds || !compounds.length > 0 || !compounds[0]) return false;

        // Data format for collapsible table is array of arrays
        // [ ... [molPropName, molPropValue] ... ]
        const compoundNames = compounds.map((c) => encodeHtmlEntities(c.resname));
        const data = [molPropTableEntry(molPropsTableTitle, compoundNames)];
        const metadata = [molPropRowMetadata(molPropsTableTitle)];

        for (const prop of orderedProps) {
            const allMolPropses = compounds.map((c) => c && c.getMolProps());
            const values = [];
            for (const molProps of allMolPropses) {
                if (molProps && Object.keys(molProps).includes(prop)) {
                    values.push(molProps[prop]);
                }
            }
            if (values.length > 0) {
                data.push(molPropTableEntry(prop, values));
                metadata.push(molPropRowMetadata(prop));
            }
        }

        // Don't actually have any mol props, so request failed.
        // Show error and refresh button.
        if (data.length === 1) {
            const { working } = this.state;
            data[0] = data[0].slice(0, 1); // remove compound names if any
            data.push(molPropTableEntry('<i>Mol. props are unavailable</i>',
                <RefreshButton
                    onClick={() => this.refreshMolProps()}
                    title="Retry molecule properties"
                    working={working}
                />));
            metadata.push({ key: 'UnavailableMolProps', title: 'BMaps was unable to reach the molecular properties service.' });
        }

        return (
            <div id="molprops_wrapper">
                <CollapsibleTable
                    eltId="molprops_table"
                    tableClassName="info-display-table"
                    data={data}
                    rowMetadata={metadata}
                    onUpdated={updateMolPropsTooltips}
                    dangerouslySetInnerHTML
                    rememberBy={MolPropsDisplay}
                    dataClassName="molprops_row"
                    headerClassName="molprops_row"
                    expanded
                    // When collapsed, only show the molprops title
                    // This slice params achieve this.
                    collapsedColSlice={[0, 1]}
                />
            </div>
        );
    }
}

export const orderedProps = [
    MolProps.MolWeight,
    MolProps.LogP,
    MolProps.EnergyEfficiency,
    MolProps.PolarSurfaceArea,
    MolProps.HeavyAtoms,
    MolProps.Charge,
    MolProps.HBondDonors,
    MolProps.HBondAcceptors,
    MolProps.RotatableBonds,
    MolProps.LipinskiViolationCount,
    MolProps.Fsp3,
];

export function updateMolPropsTooltips() {
    const tooltipInfo = {
        molprops_row: { side: 'right' },
    };
    updateTooltipster(tooltipInfo);
}

// Return a table entry for mol prop: [label, value1, value2...]
function molPropTableEntry(prop, valuesOrig) {
    let values = [].concat(valuesOrig); // force to array
    const displayInfo = molPropsDisplayInfo[prop];
    if (!displayInfo) return [prop].concat(values);

    if (displayInfo.transform) {
        values = values.map((val) => displayInfo.transform(val));
    }

    const label = displayInfo.label;
    return [label].concat(values);
}

// Return a metadata object for a data row: {key, title}
function molPropRowMetadata(prop) {
    const displayInfo = molPropsDisplayInfo[prop];
    const title = displayInfo ? displayInfo.title : prop;
    return { key: prop, title };
}

function applyPrecision(val, precision) {
    return typeof (val) === 'number' ? val.toFixed(precision) : val;
}

// Information to display molecule properties:
// label, title (ie tooltip), and a transformation function for values
const molPropsDisplayInfo = {
    [molPropsTableTitle]: { label: molPropsTableTitle, title: 'Pin compounds to view properties side-by-side' },
    [MolProps.Charge]: { label: MolProps.Charge, title: 'Formal Charge' },
    [MolProps.HBondAcceptors]: { label: MolProps.HBondAcceptors, title: 'Hydrogen bond acceptors' },
    [MolProps.HBondDonors]: { label: MolProps.HBondDonors, title: 'Hydrogen bond donors' },
    [MolProps.HeavyAtoms]: { label: MolProps.HeavyAtoms, title: 'Heavy atom count' },
    [MolProps.MolWeight]: {
        label: MolProps.MolWeight,
        title: 'Molecular weight',
        transform: (x) => applyPrecision(x, 1),
    },
    [MolProps.PolarSurfaceArea]: {
        label: MolProps.PolarSurfaceArea,
        title: 'Topological polar surface area',
        transform: (x) => (x < 0.1 ? 'N/A' : applyPrecision(x, 1)),
    },
    [MolProps.RotatableBonds]: { label: MolProps.RotatableBonds, title: 'Rotatable bond count' },
    [MolProps.LogP]: {
        label: MolProps.LogP,
        title: 'XLogP calculated by OpenBabel',
        transform: (x) => applyPrecision(x, 1),
    },
    [MolProps.EnergyEfficiency]: {
        label: MolProps.EnergyEfficiency,
        title: 'Energy efficiency = -(Energy Score) / (# heavy atoms)',
        transform: (x) => applyPrecision(x, 1),
    },
    [MolProps.LipinskiViolationCount]: {
        label: MolProps.LipinskiViolationCount,
        title: 'Lipinski\'s Rule of Five: a drug-like compound should have no more than 5 H-bond donors, no more than 10 H-bond acceptors, Mol. Wt. < 500, LogP <= 5',
    },
    [MolProps.Fsp3]: {
        label: MolProps.Fsp3,
        title: 'Fraction of sp3-hybridized carbons (sp3 carbons / total carbons)',
        transform: (x) => (x != null ? applyPrecision(x, 1) : 'N/A'),
    },
};
